
import {
  Component,
  Inject,
  InjectReactive,
  Ref,
  Vue,
  Watch
} from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";
import SteamBotItemsListDialog from "@/components/admin/SteamBotItemsListDialog.vue";
import * as SteamTotp from "steam-totp";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';;
import * as _ from "lodash";
import {
  ADMIN_PROFILE_SYMBOL,
  API_ENDPOINT_SYMBOL,
  BALANCE_RATES_SYMBOL
} from "@/constants";
import SteamItemsList from "@/components/steam/SteamItemsList.vue";
import gql from "graphql-tag";
import recursionMutate from "../mixins/recursionMutate";
import createMutation from "../mixins/createMutation";
import multipleMutate from "../mixins/multipleMutate";
import { ref } from "vue";
import {
  currencyBySteamBalance,
  steamCurrencyByBalance
} from "@/helpers/steam-currency-by-balance";

let balanceRates: any = {};

@Component({
  methods: { currencyBySteamBalance, steamCurrencyByBalance },
  components: {
    AdminTable,
    SteamItemsList,
    SteamBotItemsListDialog
  },
  mixins: [recursionMutate, createMutation, multipleMutate],
  apollo: {
    partners: {
      query: gql`
        query {
          getAllPartners {
            _id
            name
            avatar
          }
        }
      `,
      fetchPolicy: "no-cache",
      update(data) {
        if (data && data.getAllPartners) {
          const columns = this._columns();
          const index = columns.findIndex(
            ({ field }) => field === "partnerUserIds"
          );

          columns[index].enum = data.getAllPartners;
          this.partners = data.getAllPartners;
        }

        return data && data.getAllPartners ? data.getAllPartners : [];
      }
    }
  }
})
export default class AdminSteamBot extends Vue {
  @InjectReactive(BALANCE_RATES_SYMBOL) balanceRates: any;
  @Inject(API_ENDPOINT_SYMBOL) apiEndPoint;
  @InjectReactive(ADMIN_PROFILE_SYMBOL) public admin: any | null;
  @Ref("table") table: any;
  private QBtnDropdown = QBtnDropdown;
  searchInput: string = "";
  filterDialog = false;
  selectedBots: any[] = [];
  creationDialog = false;
  myBots = false;
  showAllData = this.$store.state.showAllBotsData;
  myBanBots = false;
  passwordChangeDialog = false;
  showSendAllItemsDialog = false;
  recoveryPasswordData: any = null;
  recoveryPasswordCode = "";
  recoveryPasswordNewPassword = "";
  skipRemoveEvent = false;
  loadingUpdateSteamBotItems = false;
  loadingEnableSteamBotMarketCsgo = false;
  loadingGetMarketCsgoCookieString = false;
  loadingGetSteamBotsOffersStats = false;
  loadingSetSteamBotMarketCsgoWithdrawPassword = false;
  loadingAcceptSteamBotOffers = false;
  inventoryDialogShow = false;
  createSteamMarketBuyOrderPrice = 360;
  createSteamMarketBuyOrderMarketHashName =
    "StatTrak™ AWP | Worm God (Well-Worn)";
  createSteamMarketBuyOrderDialog = false;
  activateWalletCodeDialog = false;
  selectedAppId = 730;
  inventorySteamId = "";
  walletCode = "";
  partners: any[] = [];
  files: any[] = [];
  endTime: number = Date.now() + 24 * 60 * 60 * 1000;

  showTestDialog = false;
  testDialogValue = "";

  steamItemsPriceGte = "";
  steamItemsPriceLte = "";
  steamItemsCountGte = "";
  steamItemsCountLte = "";
  marketCsgoBalanceGte = "";
  marketCsgoBalanceLte = "";
  steamBotCreatedAtGte = "";
  steamBotCreatedAtLte = "";
  steamBotSteamIds = "";
  steamBotTransferred = null;
  deleteBeforeCreate = false;
  steamBotEnabled = null;
  steamBotBanned = null;
  steamBotNonApi = null;
  steamBotMyBots = false;
  appIdFilter = null;
  partnerUserIdsFilter = null;

  @Watch("showAllData", { immediate: true })
  onChanged(value) {
    this.$store.commit("toggleBotData", value);
  }

  @Watch("balanceRates", { immediate: true })
  onBalanceRatesChange(value) {
    balanceRates = value;
  }

  @Watch("searchInput", { immediate: true })
  onSearchInputChange(value: string) {
    if (value.includes('{"pass":')) {
      const test = value.split("  ");
      let test2 = "[\n";

      test.forEach(v => {
        const steamId = (v.match(/"steamid": "(\d+)"/) || [])[1];
        const username = (v.match(/"account_name": "(.+)",\s+"sh/) || [])[1];
        const password = (v.match(/"pass": "(.+)",\s+"stea/) || [])[1];
        const sharedSecret = (v.match(/"shared_secret": "(.+)",\s+"ide/) ||
          [])[1];
        const identitySecret = (v.match(/"identity_secret": "(.+)"/) || [])[1];

        test2 += `[\n${steamId}\n${username}\n${password}\n${sharedSecret}\n${identitySecret}\nnull\nnull\nnull\nnull\n0\n]\n`;
      });

      test2 += "]";
      this.searchInput = test2;
    } else {
      this.searchInput = (value.match(/[^\s|\[|\]]+/gm) || []).join(",");
      let botData = this.searchInput.split(",");

      if (botData.length) {
        this.creationBot.steamId.value = botData[0];
        this.creationBot.username.value = botData[1];
        this.creationBot.password.value = botData[2];
        this.creationBot.sharedSecret.value = botData[3];
        this.creationBot.identitySecret.value = botData[4];
        this.creationBot.apiKey.value =
          botData[5] == "null" ? undefined : botData[5];
        this.creationBot.tradeUrl.value =
          botData[6] == "null" ? undefined : botData[6];
        this.creationBot.emailUsername.value =
          botData[7] == "null" ? undefined : botData[7];
        this.creationBot.emailPassword.value =
          botData[8] == "null" ? undefined : botData[8];
      }
    }
  }

  async copyChangedPasswordsData() {
    const data = this.selectedBots.reduce((acc, bot) => {
      acc += `'${bot._id}:${bot.emailUsername}:${bot.emailPassword}:${bot.username}:${bot.password}',\n`;

      return acc;
    }, "");

    await copyToClipboard(data);
  }

  async statistics() {
    const { data } = await this.$apollo.query({
      query: gql`
        query($startTime: Float!, $endTime: Float!, $selectedBots: [String!]) {
          getSteamBotsOffersStats(
            startTime: $startTime
            endTime: $endTime
            selectedBots: $selectedBots
          )
        }
      `,
      variables: {
        startTime: 0,
        endTime: Date.now(),
        selectedBots: this.selectedBots.map(({ _id }) => _id)
      }
    });
    const a = this.selectedBots.reduce((acc, bot) => {
      acc[bot._id] =
        (data.getSteamBotsOffersStats[bot._id] || { WITHDRAW: 0 }).WITHDRAW ||
        0;
      return acc;
    }, {});

    await copyToClipboard(
      JSON.stringify(a)
        .replaceAll("{", "")
        .replaceAll("}", "")
        .replaceAll(`"`, "")
        .replaceAll(`,`, "\n")
        .replaceAll(`:`, " ")
    );
  }

  async enableBots(query?: any, enabled = true) {
    for await (const bot of this.selectedBots) {
      await this.table.updateMutate({
        variables: {
          modelName: "steamBotModel",
          _id: bot._id,
          updatedFields: { enabled }
        }
      });

      this.$q.notify({
        message: "Успех",
        multiLine: true,
        type: "positive"
      });
    }

    query && query.refetch();
  }

  async getSumMarketBalanceBots() {
    await this.$apollo.query({
      query: gql`
        query($startTime: Float!, $endTime: Float!, $selectedBots: [String!]) {
          getSteamBotsOffersStats(
            startTime: $startTime
            endTime: $endTime
            selectedBots: $selectedBots
          )
        }
      `,
      variables: {
        startTime: 0,
        endTime: Date.now(),
        selectedBots: this.selectedBots.map(({ _id }) => _id)
      }
    });

    const result = this.selectedBots.reduce((acc, bot) => {
      return acc + Number(bot.marketCsgoBalance.toFixed(2));
    }, 0);
    this.$q.notify({ type: `positive`, message: `Сумма: ${result}` });
  }

  async acceptSteamBotOffers({ variables = {} }) {
    //@ts-ignore
    return this.createMutation(
      gql`
        mutation acceptSteamBotOffers($_id: ID!) {
          acceptSteamBotOffers(_id: $_id)
        }
      `,
      variables
    );
  }

  async acceptSteamBotOffersMultiple(query) {
    this.loadingAcceptSteamBotOffers = true;
    await Promise.allSettled(
      this.selectedBots.map(bot => {
        return this["recursionMutate"](
          this.acceptSteamBotOffers,
          { _id: bot._id },
          () => {
            this.$q.notify({
              message: `Успех`,
              multiLine: true,
              type: "positive"
            });
            query.refetch();
          }
        );
      })
    );

    this.loadingAcceptSteamBotOffers = false;
  }

  async updateSteamBotItems({ variables = {} }) {
    //@ts-ignore
    return this.createMutation(
      gql`
        mutation updateSteamBotItems($_id: ID!) {
          updateSteamBotItems(_id: $_id)
        }
      `,
      variables
    );
  }

  async updateSteamBotItemsMultiple(query) {
    this.loadingUpdateSteamBotItems = true;
    await Promise.allSettled(
      this.selectedBots.map(bot => {
        return this["recursionMutate"](
          this.updateSteamBotItems,
          { _id: bot._id },
          () => {
            this.$q.notify({
              message: `Успех`,
              multiLine: true,
              type: "positive"
            });
            query.refetch();
          }
        );
      })
    );

    this.loadingUpdateSteamBotItems = false;
  }

  async enableSteamBotMarketCsgoMultiple(variablesArr?: any[], query?: any) {
    this.loadingEnableSteamBotMarketCsgo = true;
    await Promise.allSettled(
      this.selectedBots.map(bot => {
        return this["recursionMutate"](
          this.enableSteamBotMarketCsgo,
          { steamBotId: bot._id },
          () => {
            this.$q.notify({
              message: `Успех`,
              multiLine: true,
              type: "positive"
            });
            query.refetch();
          }
        );
      })
    );

    this.loadingEnableSteamBotMarketCsgo = false;
  }

  async enableSteamBotMarketCsgo({ variables = {} }) {
    //@ts-ignore
    return this.createMutation(
      gql`
        mutation enableSteamBotMarketCsgo($steamBotId: ID!) {
          enableSteamBotMarketCsgo(steamBotId: $steamBotId)
        }
      `,
      variables
      /*`loadingEnableSteamBotMarketCsgo`*/
    );
  }

  async setSteamBotMarketCsgoWithdrawPasswordMultiple(
    variablesArr?: any[],
    query?: any
  ) {
    this.loadingSetSteamBotMarketCsgoWithdrawPassword = true;
    await Promise.allSettled(
      this.selectedBots.map(bot => {
        return this["recursionMutate"](
          this.setSteamBotMarketCsgoWithdrawPassword,
          { steamBotId: bot._id },
          () => {
            this.$q.notify({
              message: `Успех`,
              multiLine: true,
              type: "positive"
            });
            query.refetch();
          },
          1
        );
      })
    );

    this.loadingSetSteamBotMarketCsgoWithdrawPassword = false;
  }

  async setSteamBotMarketCsgoWithdrawPassword({ variables = {} }) {
    //@ts-ignore
    return this.createMutation(
      gql`
        mutation setSteamBotMarketCsgoWithdrawPassword($steamBotId: ID!) {
          setSteamBotMarketCsgoWithdrawPassword(steamBotId: $steamBotId)
        }
      `,
      variables
      /*`loadingEnableSteamBotMarketCsgo`*/
    );
  }

  uploadTxtBotData(files) {
    this.files.length
      ? files.map(f => this.files.push(f))
      : (this.files = files);
    this.readTxtBotData(this.files[0]);
  }

  async saveBotData() {
    await copyToClipboard(
      JSON.stringify(
        this.selectedBots.map(
          ({
            _id,
            username,
            password,
            sharedSecret,
            identitySecret,
            apiKey,
            tradeUrl,
            emailUsername,
            emailPassword,
            steamItemsPrice
          }) =>
            Object.values({
              steamBotId: _id,
              username,
              password,
              sharedSecret,
              identitySecret,
              apiKey,
              tradeUrl,
              emailUsername,
              emailPassword,
              steamItemsPrice
            })
        ),
        null,
        2
      )
        .replaceAll('"', "")
        .replaceAll("\,", "")
    );
  }

  async saveBotDataAnotherForm() {
    const result: Array<any> = [];
    let expireTime = 0;

    for await (const bot of this.selectedBots) {
      const [authData, ip] = bot.proxy.replace(/.+:\/\//, "").split("@");
      const proxy = `${ip}:${authData}`;
      const { data } = await this.$apollo.query({
        query: gql`
          query($proxyId: String!) {
            getProxyExpireTime(proxyId: $proxyId)
          }
        `,
        variables: {
          proxyId: bot.proxyId
        }
      });

      let year, day, month;

      if (data.getProxyExpireTime.includes(".")) {
        [day, month] = data.getProxyExpireTime.split(".");
        year = new Date(Date.now()).getFullYear();
      } else {
        [year, month, day] = data.getProxyExpireTime.split("-");
        year = year.split(" ")[1];
      }

      if (data.getProxyExpireTime && day?.length <= 2 && month?.length <= 2) {
        const expireDate = new Date(`${year}, ${month}, ${day}`).getTime();

        expireTime = Math.floor(
          (expireDate - Date.now()) / (1000 * 60 * 60 * 24)
        );
        expireTime = expireTime > 0 ? expireTime : 0;

        if (!day || !month) {
          expireTime = 0;
        }
      }

      const reminderDay = (bot.tradableAfter || "").slice(8, 10),
        reminderMonth = (bot.tradableAfter || "").slice(5, 7),
        reminder = `${reminderDay}.${reminderMonth}`;

      const botFormData = {
        username: bot.username,
        password: bot.password,
        proxy,
        proxy_lifetime_days: expireTime,
        cost: 0,
        shared_secret: bot.sharedSecret,
        identity_secret: bot.identitySecret,
        reminder,
        color: "gray",
        need_change_nickname: false,
        all_time: false,
        need_change_password: false
      };

      result.push(botFormData);
    }

    await copyToClipboard(JSON.stringify(result, null, 2));
  }

  onRemoveFile(file) {
    if (!this.skipRemoveEvent) {
      this.files = this.files.filter(f => f !== file[0]);

      if (this.files.length) {
        this.readTxtBotData(this.files[0]);
      }
    }

    this.skipRemoveEvent = false;
  }

  readTxtBotData(files, cb = () => {}) {
    // use the 1st file from the list
    const file = files;
    const reader = new FileReader();

    // Closure to capture the file information.
    reader.onload = (theFile => {
      return e => {
        const content = e.target.result;
        Object.keys(this.creationBot).forEach(key => {
          const value = this.creationBot[key];
          if (value.fileRules) {
            for (const rule of value.fileRules) {
              let setValue = undefined;

              if (Array.isArray(rule)) {
                const match = content.match(rule[0]);

                if (match && match[rule[1]]) {
                  setValue = match[rule[1]];
                }
              } else {
                let match = rule.exec(content);

                if (!match) {
                  match = rule.exec(content);
                }

                if (key === "username" || key === "password") {
                  let o = Object.fromEntries(
                    Object.entries(match).filter(([_, v]) => v != undefined)
                  );
                  match[1] = o[Object.keys(o)[1]];
                }

                if (match && match[1]) {
                  setValue = match[1];
                }
              }

              if (setValue) {
                value.value = setValue;
                break;
              }
            }
          }
        });

        cb();
      };
    })(file);

    // Read in the image file as a data URL.
    reader.readAsText(file);
  }

  async getMarketCsgoCookieString({ variables = {} }) {
    //@ts-ignore
    return this.createMutation(
      gql`
        mutation getMarketCsgoCookieString($steamBotId: ID!, $saved: Boolean) {
          getMarketCsgoCookieString(steamBotId: $steamBotId, saved: $saved)
        }
      `,
      variables,
      `loadingGetMarketCsgoCookieString`
    );
  }

  created() {
    // if (!this.admin || this.admin.role !== "ADMIN") {
    //   delete this.creationBot.partnerUserIds;
    // }

    const serverName = this.$q.cookies.get("selected-server");
    if ((serverName || "").indexOf("SELL") !== -1) {
      this.creationBot.type.value = "SELL";
    }
  }

  get creationBotVariables() {
    const res = Object.keys(this.creationBot).reduce((acc, c) => {
      acc[c] = this.creationBot[c].value;
      return acc;
    }, {});

    res["deleteBeforeCreate"] = this.deleteBeforeCreate || false;

    return res;
  }

  async createButtonClick() {
    await this.$apollo.query({
      query: gql`
        query {
          getProxyList {
            _id
            url
            rotator
          }
        }
      `,
      fetchPolicy: "no-cache"
    });

    this.$nextTick(() => {
      const el: HTMLElement | null = document.querySelector(".create-bot");
      setTimeout(() => {
        if (el) {
          el.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window
            })
          );
        }
      }, 10);
    });
  }

  creationBotReset() {
    Object.keys(this.creationBot).forEach(key => {
      if (["type", "partnerUserIds", "needPasswordChange"].includes(key)) {
        return;
      }
      this.creationBot[key].value = undefined;
    });

    if (this.files.length) {
      const uploadedFile = this.files[0];
      this.files.shift();
      this.skipRemoveEvent = true;
      (this.$refs.abc as any).removeFile(uploadedFile);

      this.files.length &&
        this.readTxtBotData(this.files[0], () => {
          this.createButtonClick();
        });
    }

    if (this.searchInput.length) {
      this.searchInput = this.searchInput
        .split(",")
        .slice(10)
        .join(",");

      this.searchInput.length && this.createButtonClick();
    }
  }

  get lastSelectedBot() {
    return _.last(this.selectedBots) || null;
  }

  async copyToClipboard(value) {
    return await copyToClipboard(value);
  }

  async copyAuthCode() {
    const code = SteamTotp.generateAuthCode(this.lastSelectedBot.sharedSecret);
    await copyToClipboard(code);
    this.$q.notify({ type: `positive`, message: `Скопировано: ${code}` });
  }

  _columns() {
    if (this.showAllData) {
      return this.columns;
    } else {
      return this.shortColumns;
    }
  }

  toggleShowAllBotsData() {
    this.$store.commit("showAllBotsData", !this.showAllData);
  }

  getFilterCondition(inputName: string, gte?: string, lte?: string) {
    if (inputName === "createdAt") {
      gte = gte
        ? gte
            .split(".")
            .reverse()
            .join("-")
        : "";
      lte = lte
        ? lte
            .split(".")
            .reverse()
            .join("-")
        : "";

      return gte
        ? `${inputName}: {$gte: "${gte}" ${lte ? `, $lte: "${lte}"` : ""}}`
        : lte
        ? `${inputName}: {$lte: "${lte}"}`
        : "";
    }

    return gte
      ? `${inputName}: {$gte: ${gte}${lte ? `, $lte: ${lte}` : ""}}`
      : lte
      ? `${inputName}: {$lte: ${lte}}`
      : "";
  }

  // New Filter
  searchFilteredBots() {
    let conditions: string[] = [];

    const steamItemsCountCond = this.getFilterCondition(
      "steamItemsCount",
      this.steamItemsCountGte,
      this.steamItemsCountLte
    );
    const steamItemsPriceCond = this.getFilterCondition(
      "steamItemsPrice",
      this.steamItemsPriceGte,
      this.steamItemsPriceLte
    );
    const marketCsgoBalanceCond = this.getFilterCondition(
      "marketCsgoBalance",
      this.marketCsgoBalanceGte,
      this.marketCsgoBalanceLte
    );
    const steamBotCreatedAtCond = this.getFilterCondition(
      "createdAt",
      this.steamBotCreatedAtGte,
      this.steamBotCreatedAtLte
    );

    const steamBotIds = this.steamBotSteamIds.match(/(7\d{16})/gim);
    const steamBotSteamIdsCond =
      steamBotIds && steamBotIds.length
        ? `_id: {$in: [${steamBotIds.map(v => `"${v}"`).join(", ")}]}`
        : "";

    const steamBotBannedCond =
      this.steamBotBanned === null
        ? ""
        : this.steamBotBanned
        ? `banned: true`
        : "banned: false";

    const steamBotTransferredCond =
      this.steamBotTransferred === null
        ? ""
        : this.steamBotTransferred
        ? `transferred: true`
        : "transferred: false";

    const steamBotNonApiCond =
      this.steamBotNonApi === null
        ? ""
        : this.steamBotNonApi
        ? `nonApi: true`
        : "nonApi: false";

    const steamBotEnabledCond =
      this.steamBotEnabled === null
        ? ""
        : this.steamBotEnabled
        ? `enabled: true`
        : "enabled: false";

    const steamBotMyBots = this.steamBotMyBots
      ? `creatorId: "${this.admin._id}"`
      : "";

    const steamBotAppIdCond =
      this.appIdFilter === null ? "" : `appId: ${this.appIdFilter}`;

    const steamBotPartnerCond =
      this.partnerUserIdsFilter === null
        ? ""
        : this.partnerUserIdsFilter === "skinsplus"
        ? '"partnerUserIds":{"$size":0}'
        : `"partnerUserIds": "${this.partnerUserIdsFilter}"`;

    conditions.push(
      steamItemsCountCond,
      steamItemsPriceCond,
      marketCsgoBalanceCond,
      steamBotBannedCond,
      steamBotTransferredCond,
      steamBotNonApiCond,
      steamBotEnabledCond,
      steamBotCreatedAtCond,
      steamBotSteamIdsCond,
      steamBotMyBots,
      steamBotAppIdCond,
      steamBotPartnerCond
    );

    conditions = conditions.filter(v => v.length > 1);

    this.table.searchConditionText = conditions.length
      ? `{${conditions.join(", ")}}`
      : "";
  }

  creationBot: any = {
    steamId: {
      label: "steamId",
      value: undefined,
      required: true,
      type: "string",
      fileRules: [
        ///(7\d{16})/gm
        /(7\d{16})/im
      ]
    },
    username: {
      label: "username",
      value: undefined,
      required: true,
      type: "string",
      fileRules: [
        /^(?!android)([\w\d_-]+):[\w\d_-]+$|^(?!android)([\w\d_-]+):[\w\d_-]+|^Login:\s{0,}(.+)/m
        // /^(?!android)([\w\d_-]+):[\w\d_-]+$/im
      ]
    },
    password: {
      label: "password",
      value: undefined,
      required: true,
      type: "string",
      fileRules: [
        /^(?!android)[\w\d\/$#%_-{}()\[\]_-]+:([\w\d\/$#%_-{}()\[\]_-]+)$|^(?!android)[\w\d\/$#%_-{}()\[\]_-]+:([\w\d\/$#%_-{}()\[\]_-]+)+|^Password:\s{0,}(.+)/m
        // /^(?!android)[\w\d_-]+:([\w\d\S_-]+)$/im
      ]
    },
    sharedSecret: {
      label: "sharedSecret",
      value: undefined,
      required: true,
      type: "string",
      fileRules: [
        [
          /[A-Za-z0-9+\/]{27}=/gm,
          // /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4}=)$/gim,
          0
        ]
      ]
    },
    identitySecret: {
      label: "identitySecret",
      value: undefined,
      required: true,
      type: "string",
      fileRules: [
        [
          /[A-Za-z0-9+\/]{27}=/gm,
          //  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4}=)$/gim,
          1
        ]
      ]
    },
    apiKey: {
      label: "apiKey",
      value: undefined,
      required: true,
      editable: true,
      type: "string",
      fileRules: [
        /([\d\w]{32}$)/im
        //  /^([\d\w]{32})$/im
      ]
    },
    proxyId: {
      label: "proxyId",
      value: undefined,
      required: true,
      type: "string"
    },
    tradeUrl: {
      label: "tradeUrl",
      value: undefined,
      required: true,
      type: "string",
      fileRules: [
        /(https?:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=\d+&token=[a-zA-Z0-9_-]+)/im
      ]
    },
    type: {
      label: "type",
      value: `BUY`,
      required: true,
      type: "string"
    },
    appId: {
      label: "appId",
      value: "CSGO",
      type: "string"
    },
    partnerUserIds: {
      label: "partnerUserIds",
      value: [],
      type: "array"
    },
    emailUsername: {
      label: "emailUsername",
      value: undefined,
      type: "string",
      fileRules: [
        /^(?!android)([\w\d_-]+@[\w\d_-]+\.\w+):[^\s]+$/im,
        /=*\sMAIL\s=*\nLogin:\s*([\w\d_-]+@[\w\d_-]+\.\w+)/im
      ]
    },
    emailPassword: {
      label: "emailPassword",
      value: undefined,
      type: "string",
      fileRules: [
        /^(?!android)[\w\d_-]+@[\w\d_-]+\.\w+:([^\s]+)$/im,
        /=*\sMAIL\s=*\nLogin:.+\nPassword:\s*(.+)$/im
      ]
    },
    needPasswordChange: {
      label: "Сменить пароль",
      value: false,
      type: "boolean"
    }
  };
  columns = [
    {
      name: "_id",
      required: true,
      label: "Steam ID",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "banned",
      align: "center",
      label: "banned",
      field: "banned",
      editable: true,
      type: "boolean"
    },
    {
      name: "passwordChanged",
      align: "right",
      label: "passwordChanged",
      field: "passwordChanged",
      sortable: false,
      type: "boolean"
    },

    {
      name: "emailPasswordChanged",
      align: "right",
      label: "emailPasswordChanged",
      field: "emailPasswordChanged",
      sortable: false,
      type: "boolean"
    },
    {
      name: "transferred",
      align: "center",
      label: "transferred",
      field: "transferred",
      editable: true,
      type: "boolean"
    },
    {
      name: "username",
      align: "center",
      label: "Логин",
      field: "username",
      editable: true,
      type: "string"
    },
    {
      name: "password",
      align: "center",
      label: "Пароль",
      field: "password",
      classes: "ellipsis",
      style: "max-width: 50px",
      editable: true,
      type: "string"
    },
    {
      name: "sharedSecret",
      align: "center",
      label: "sharedSecret",
      field: "sharedSecret",
      classes: "ellipsis",
      style: "max-width: 10px",
      editable: true,
      type: "string"
    },
    {
      name: "identitySecret",
      align: "center",
      label: "identitySecret",
      field: "identitySecret",
      classes: "ellipsis",
      style: "max-width: 10px",
      editable: true,
      type: "string"
    },
    {
      name: "apiKey",
      align: "center",
      label: "apiKey",
      field: "apiKey",
      classes: "ellipsis",
      style: "max-width: 10px",
      editable: true,
      type: "string"
    },
    {
      name: "partnerUserIds",
      align: "center",
      label: "partnerUserIds",
      field: "partnerUserIds",
      editable: true,
      optionLabel: "name",
      optionValue: `_id`,
      type: "array",
      enum: []
    },
    {
      name: "tradeUrl",
      align: "center",
      label: "tradeUrl",
      field: "tradeUrl",
      classes: "ellipsis",
      style: "max-width: 50px",
      editable: true,
      type: "string"
    },
    /*  {
      name: "proxy",
      align: "center",
      label: "proxy",
      field: "proxy",
      classes: "ellipsis",
      style: "max-width: 50px",
      type: "string"
    },*/
    {
      name: "proxyId",
      align: "center",
      label: "proxyId",
      field: "proxyId",
      classes: "ellipsis",
      style: "max-width: 50px",
      editable: true,
      type: "string"
    },
    {
      name: "type",
      align: "center",
      label: "type",
      field: "type",
      editable: true,
      enum: ["BUY", "SELL"],
      type: "string"
    },
    {
      name: "appId",
      align: "center",
      label: "appId",
      field: "appId",
      editable: true,
      enum: [730, 570, 440, 252490],
      type: "number"
    },
    {
      name: "steamItemsCount",
      align: "center",
      label: "Кол-во скинов",
      field: "steamItemsCount",
      editable: true,
      type: "number"
    },
    {
      name: "steamItemsPrice",
      align: "center",
      label: "Цена",
      field: "steamItemsPrice",
      editable: true,
      type: "number"
    },
    {
      name: "steamItemsPrice$",
      align: "center",
      label: "Цена $",
      field: "steamItemsPrice",
      editable: false,
      format: v => "$" + Number((v / (balanceRates.USD || 1)).toFixed(2)),
      type: "string"
    },
    {
      name: "marketCsgoBalance",
      align: "center",
      label: "ТМ баланс",
      field: "marketCsgoBalance",
      editable: true,
      type: "number"
    },
    {
      name: "steamBalance",
      align: "center",
      label: "Steam баланс",
      field: "steamBalance",
      editable: true,
      type: "number"
    },
    {
      name: "enabled",
      align: "center",
      label: "enabled",
      field: "enabled",
      editable: true,
      type: "boolean"
    },
    {
      name: "forExpensiveItems",
      align: "center",
      label: "forExpensiveItems",
      field: "forExpensiveItems",
      editable: true,
      type: "boolean"
    },
    {
      name: "nonApi",
      align: "center",
      label: "nonApi",
      field: "nonApi",
      editable: true,
      type: "boolean"
    },
    {
      name: "marketCsgoApiKey",
      align: "center",
      label: "marketCsgoApiKey",
      field: "marketCsgoApiKey",
      classes: "ellipsis",
      style: "max-width: 10px",
      editable: true,
      type: "string"
    },
    {
      name: "createdAt",
      align: "right",
      label: "createdAt",
      field: "createdAt",
      sortable: true,
      type: "string"
    },
    {
      name: "tradableAfter",
      align: "right",
      label: "tradableAfter",
      field: "tradableAfter",
      sortable: true,
      type: "string"
    },
    {
      name: "emailPassword",
      align: "right",
      label: "emailPassword",
      field: "emailPassword",
      sortable: false,
      type: "string"
    },

    {
      name: "emailUsername",
      align: "right",
      label: "emailUsername",
      field: "emailUsername",
      sortable: false,
      type: "string"
    }
  ];
  shortColumns = [
    {
      name: "_id",
      required: true,
      label: "Steam ID",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "banned",
      align: "center",
      label: "banned",
      field: "banned",
      editable: true,
      type: "boolean"
    },
    {
      name: "passwordChanged",
      align: "right",
      label: "passwordChanged",
      field: "passwordChanged",
      sortable: false,
      type: "boolean"
    },
    {
      name: "emailPasswordChanged",
      align: "right",
      label: "emailChanged",
      field: "emailPasswordChanged",
      sortable: false,
      type: "boolean"
    },
    {
      name: "transferred",
      align: "center",
      label: "transferred",
      field: "transferred",
      editable: true,
      type: "boolean"
    },
    {
      name: "username",
      align: "center",
      label: "Логин",
      field: "username",
      editable: true,
      type: "string"
    },
    {
      name: "apiKey",
      align: "center",
      label: "apiKey",
      field: "apiKey",
      classes: "ellipsis",
      style: "max-width: 10px",
      editable: true,
      type: "string"
    },
    {
      name: "partnerUserIds",
      align: "center",
      label: "partnerUserIds",
      field: "partnerUserIds",
      editable: true,
      optionLabel: "name",
      optionValue: `_id`,
      type: "array",
      enum: []
    },
    {
      name: "type",
      align: "center",
      label: "type",
      field: "type",
      editable: true,
      enum: ["BUY", "SELL"],
      type: "string"
    },
    {
      name: "appId",
      align: "center",
      label: "appId",
      field: "appId",
      editable: true,
      enum: [730, 570, 440, 252490],
      type: "number"
    },
    {
      name: "steamItemsCount",
      align: "center",
      label: "Кол-во скинов",
      field: "steamItemsCount",
      editable: true,
      type: "number"
    },
    {
      name: "steamItemsPrice",
      align: "center",
      label: "Цена",
      field: "steamItemsPrice",
      editable: true,
      type: "number"
    },
    {
      name: "steamItemsPrice$",
      align: "center",
      label: "Цена $",
      field: "steamItemsPrice",
      editable: false,
      format: v => "$" + Number((v / (balanceRates.USD || 1)).toFixed(2)),
      type: "string"
    },
    {
      name: "steamBalance",
      align: "center",
      label: "Steam баланс",
      field: "steamBalance",
      editable: true,
      type: "number"
    },
    {
      name: "enabled",
      align: "center",
      label: "enabled",
      field: "enabled",
      editable: true,
      type: "boolean"
    },
    {
      name: "nonApi",
      align: "center",
      label: "nonApi",
      field: "nonApi",
      editable: true,
      type: "boolean"
    },
    {
      name: "createdAt",
      align: "right",
      label: "createdAt",
      field: "createdAt",
      sortable: true,
      type: "string"
    },
    {
      name: "tradableAfter",
      align: "right",
      label: "tradableAfter",
      field: "tradableAfter",
      sortable: true,
      type: "string"
    }
  ];
}
